import React, { useState, useEffect } from "react";
import "./App.css";
import logo from "./logo.png"; // Logo için
import weddiApp from "./weddi_app.png"; // İlk uygulama ikonu
import photer from "./photer.png"; // İkinci uygulama ikonu
import monailisa from "./logoo.png"; // İkinci uygulama ikonu
import modipic from "./modipic.png"; // İkinci uygulama ikonu
import diress from "./diress.png"; // İkinci uygulama ikonu
import separai from "./separai.png"; // İkinci uygulama ikonu

function App() {
  const [loading, setLoading] = useState(true); // Loading state

  // Dinamik olarak assetlerin yüklenmesini izlemek
  useEffect(() => {
    const handleLoad = () => {
      setLoading(false); // Yükleme tamamlandığında loading state false olacak
    };

    if (document.readyState === "complete") {
      // Sayfa zaten yüklendiyse
      handleLoad();
    } else {
      // Dinleyiciler ekleniyor
      window.addEventListener("load", handleLoad);

      // Cleanup
      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  const currentYear = new Date().getFullYear();

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-text">
          Loading assets<span className="dot">.</span>
          <span className="dot">.</span>
          <span className="dot">.</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <video autoPlay muted loop className="background-video">
        <source src="https://un.ms//assets/pile/sky.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <h2 className="app-title">
        Our
        <br />
        Applications
      </h2>
      <header className="app-header">
        <div className="apps">
          <div className="app">
            <img src={monailisa} alt="Photer" className="app-icon" />
            <p>JewJew</p>
          </div>
          <div className="app">
            <img src={weddiApp} alt="Weddi App" className="app-icon" />
            <p>CoupleShoot</p>
          </div>
          <div className="app">
            <img src={diress} alt="Weddi App" className="app-icon" />
            <p>Diress</p>
          </div>

          <div className="app">
            <img src={modipic} alt="Photer" className="app-icon" />
            <p>Modipic</p>
          </div>
          <div className="app">
            <img src={separai} alt="Separai" className="app-icon" />
            <p>Separai</p>
          </div>
        </div>
      </header>

      <img src={logo} alt="Logo" className="bottom-logo" />

      <footer>
        <p>
          © {currentYear} Monailisa. All rights reserved |{" "}
          <a href="mailto:skozayy@gmail.com" className="contact-link">
            Contact
          </a>
        </p>
      </footer>
    </div>
  );
}

export default App;
